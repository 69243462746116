import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getStorage, connectStorageEmulator } from 'firebase/storage'

let firebaseConfig = {
  apiKey: "AIzaSyD95dBYdzWlBbUkB_T6V-A4kULLaG4X_u0",
  authDomain: "web-zep.firebaseapp.com",
  projectId: "web-zep",
  storageBucket: "web-zep.appspot.com",
  messagingSenderId: "697421995567",
  appId: "1:697421995567:web:b3641e273c7c43fba830da",
  measurementId: "G-1LET4ZB785"
}

const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const functions = getFunctions(app, "us-central1")
const storage = getStorage(app)

// Turn off for production

function connectEmulators(host: string) {
  connectAuthEmulator(auth, `http://${host}:9099`, {disableWarnings: true})
  connectFirestoreEmulator(db, host, 8080)
  connectFunctionsEmulator(functions, host, 5001)
  connectStorageEmulator(storage, host, 9199)
}

function turnOnEmulators() {
  switch (window.location.hostname) {
    case 'localhost':
      connectEmulators('localhost')
      break
    case '127.0.0.1':
      connectEmulators('127.0.0.1')
      break
    case '192.168.0.5':
      connectEmulators('192.168.0.5')
      break
    default:
      break
  }
}

// turnOnEmulators() 

export { firebaseConfig, app, auth, db, functions, storage }
