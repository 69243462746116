import React, { useRef, useState, useEffect } from 'react'

type Props = {
  src: string
  alt: string
  onLoad?: React.ReactEventHandler<HTMLImageElement>
}

function SquareImage(props: Props) {
  const { src, alt, onLoad } = props
  const ref = useRef<HTMLDivElement>(null)
  const [sideLength, setSideLength] = useState<number | null>()
  
  useEffect(() => {
    if (ref.current) {
      let tempSideLength = Math.round(window.innerHeight * 0.07)
      const parentWidth = ref.current?.parentElement?.offsetWidth
      if (parentWidth && parentWidth < (window.innerHeight * (tempSideLength / 100))) {
        tempSideLength = parentWidth
      }
      setSideLength(tempSideLength)
    }
  }, [ref])


  return (
    <div
      ref={ref}  
      style={{
        backgroundImage: `url(${src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: `${(sideLength ?? 0) > 100 ? sideLength + 'px' : sideLength + 'vh'}`,
        width: `${(sideLength ?? 0) > 100 ? sideLength + 'px' : sideLength + 'vh'}`,
      }} 
      className='rounded-3xl mx-auto my-3 text-white aspect-square object-cover w-full'
      // height={'80%'}
      onLoad={onLoad}
    /> 
  )
}

export default SquareImage