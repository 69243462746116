import React, { useEffect, useState, } from 'react'


import userDataType from '../types/userData'

import LazyLoad from 'react-lazyload'
import quiz from '../types/quiz'
import { Link } from 'react-router-dom'

interface Props {
  quizData: quiz
  userData: userDataType | null
  historyText?: string[]
}

export function Quiz(props: Props) {
  const { quizData, userData, historyText } = props
  const {title, description, image, video, category, id} = quizData

  // const [ vote, setVote ] = useState(0)
  // const [ animation, setAnimation ] = useState('')
  const [ height, setHeight ] = useState(200)

  // const { setOptions: setFireworksOptions, options: fireworkOptions, enabled: fireworksEnabled, setEnabled: setFireworksEnabled } = useFireworks({ initialStart: false, initialOptions: {
  //   rocketsPoint: {
  //     min: 0,
  //     max: 100,
  //   }
  // }})  
  
  // const particlesInit = useCallback(async (engine: Engine) => {
  //   await loadFull(engine)
  // }, [])
  
  // // finds if the user has voted on this
  // useEffect(() => {
  //   if (userData) {
  //     for (let i = 0; i < userData.votes.length; i++) {
  //       if (userData.votes[i].id === id) {
  //         setVote(userData.votes[i].vote)
  //         break
  //       }
  //     } 
  //   }
  // }, [userData, id])

  return (<>
    <Link 
      className={`flex flex-col snap-center items-center m-auto my-6 p-1 w-11/12 max-w-2xl rounded-2xl duration-300 shadow-neu bg-background-100`}
      to={`/quiz/${id}`}
      state={{
        // Passes the quiz data to the quiz page, but seperate it from userData
        quiz: quizData,
        userData: userData 
      }}
    >

      {/* {category &&
        <p className='text-white text-center font-semibold text-xl mt-2 mx-auto'>
          {`${category.substring(0, 1).toUpperCase()}${category.substring(1)}`}
        </p>
      } */}

      <div className='mx-4 mb-4 md:mb-0 flex flex-col align-middle'>
        <p className='text-accent-primary font-semibold text-2xl md:text-3xl mx-auto text-center'>
          {title}
        </p>

        <div className='relative'>
          {image && (
            <LazyLoad offset={100} height={height} unmountIfInvisible={true}>
              <img 
                src={image} 
                className='rounded-3xl mx-auto my-3 text-white aspect-square object-cover w-full'
                alt={title}
                onLoad={(el) => setHeight(el.currentTarget.height + 12 + 16)}
                draggable={false}
              />
            </LazyLoad>
          )}

          {video && (
            <LazyLoad offset={100} height={height} unmountIfInvisible={true}>
              <video
                src={video}
                width={'100%'}
                height={'100%'}
                controls={true}
                autoPlay={true}
                loop={true}
                muted={true}
                className='rounded-3xl mx-auto my-3 text-white max-h-[80vh]'
                onLoadedData={(el) => setHeight(el.currentTarget.height + 16)}
              />
            </LazyLoad>
          )}
          <div className='absolute bottom-[5%] flex flex-col justify-center align-middle h-min mx-auto w-full'>
          <div className='flex justify-center w-max mx-auto text-white font-semibold text-lg hover:scale-110 active:scale-90 transition ease-in-out'>
              <span className='bg-hate-100 hover:bg-hype-100 backdrop-blur-[2px] rounded-xl py-2 px-4'>Let's Play!</span>
              {/* <span className='bg-hate-100 rounded-r-xl py-2 pr-4 pl-1'>Play!</span> */}
          </div>
          </div>

        </div>
      </div>

      <div className={`flex flex-col justify-center align-middle h-min mx-auto`}>

        {historyText && historyText.map((text) => <>
          <p className='text-white font-medium text-xl mx-auto px-4 text-center'>
            {text}
          </p>
        </>)}

      </div>
    </Link>
    
    {/* <Fireworks 
      enabled={fireworksEnabled}
      options={fireworkOptions}
      className='fixed h-screen w-screen pointer-events-none top-0 left-0'
    />

    {(animation === 'tunnel' || animation === 'fountain') && (
      <Particles 
        className='fixed h-screen w-screen pointer-events-none top-0 left-0 -z-10'
        options={(() => {
          if (animation === 'tunnel') return getTunnel(vote)
          if (animation === 'fountain') return getFountain(vote)
          return undefined
        })()}
        init={particlesInit}
      />
    )} */}
  </>
  )
}
  