import React, { createContext, useEffect } from 'react'
import JSConfetti from 'js-confetti'


const confetti = new JSConfetti()
const ConfettiContext = createContext(confetti)

function ConfettiProvider({ children }: { children: React.ReactNode }) {  

  return (<>
    <ConfettiContext.Provider value={confetti}>
      {children}
    </ConfettiContext.Provider>
  </>)
}

export { ConfettiProvider }

export default ConfettiContext