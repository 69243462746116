import field from "../types/field"

export default [
  // {
  //   "name": "title",
  //   "label": "Title",
  //   "type": "text",
  //   "value": "title",
  //   "required": true,
  //   "spellCheck": true
  // },
  {
    "name": "category",
    "label": "Category",
    "type": "text",
    "value": "category",
    "required": false,
    "spellCheck": true
  },
  {
    "name": "subtitle",
    "label": "Title",
    "type": "text",
    "value": "subtitle",
    "required": true,
    "spellCheck": true
  },
  {
    "name": "subtext",
    "label": "Text",
    "type": "text",
    "value": "subtext",
    "required": false,
    "spellCheck": true
  },
  {
    "name": "embeddedWebsite",
    "label": "Embedded Website",
    "type": "url",
    "value": "embeddedWebsite",
    "required": false,
    "spellCheck": true
  },
  {
    "name": "file",
    "label": "File",
    "type": "file",
    "value": "file",
    "accept":  "image/png, image/jpeg, video/mp4, video/webm, video/mov, img/gif",
    "ref": "fileInput",
    "required": false,
    "spellCheck": false
  },
  {
    "name": "image",
    "label": "Image Address (Optional)",
    "type": "url",
    "value": "image",
    "required": false,
    "spellCheck": false
  },
  {
    "name": "video",
    "label": "Video Address (Optional)",
    "type": "url",
    "value": "video",
    "required": false,
    "spellCheck": false
  },
  {
    "name": "altText",
    "label": "Alt Text",
    "type": "text",
    "value": "altText",
    "required": false,
    "spellCheck": true
  },
  {
    "name": "Link",
    "label": "Link",
    "type": "url",
    "value": "link",
    "required": false,
    "spellCheck": false
  },
] as unknown as field[]