import React, { useEffect, useState } from 'react'
import IRainbowDrop from '../types/rainbowDrop'

function accountForResize(setDrops: React.Dispatch<React.SetStateAction<IRainbowDrop[]>>) {
  setDrops((drops) => 
      drops.map((drop) => {
        drop.posX = Math.floor(Math.random() * window.innerWidth)
        return drop
      })
    )
}

function RainbowRain() {
  const [drops, setDrops] = useState<IRainbowDrop[]>([])

  // https://www.schemecolor.com/respect-the-rainbow.php
  const dropColors = [
    '#EA0037',
    '#F75300',
    '#F7D716',
    '#72BB0F',
    '#007CF7',
    '#9302C6',
  ]

  useEffect(() => {
    const tmpDrops: IRainbowDrop[] = []

    // PIXELS_PER_DROP is calculated based on the fact I like 100 drops on a 1080p screen, so this is to keep the consistency across all screen widths
    // 1920 / 100 = 19.2,  && 1920 / 19.2 = 100
    const PIXELS_PER_DROP = 19.2

    // Dev.to article I stole this from: https://dev.to/j471n/colorful-rain-with-js-514j
    // Codepen In the article I stole this from: https://codepen.io/j471n/pen/GRMNeZG
    for (let i = 0; i < (window.innerWidth / PIXELS_PER_DROP); i++) {
      tmpDrops.push({
        width: Math.random() * 5,
        dropColor: dropColors[(Math.floor(Math.random() * dropColors.length))],
        duration: Math.random() * 5,
        delay: Math.random() * -20,
        opacity: Math.random() + 0.2,
        posX: Math.floor(Math.random() * window.innerWidth),
      })
    }
    setDrops(tmpDrops)
  }, [])

  useEffect(() => {
    if (drops.length > 0 && !(window.onresize)) {
      window.onresize = () => {
        accountForResize(setDrops)
      }
    }

    return () => {
      window.onresize = null
    }
  }, [drops])

  return (<>
    <div className='fixed pointer-events-none w-screen h-screen top-0 left-0 z-10 bg-transparent'>
      {drops && drops.map((drop, i) => <>
        <div
          className={`animate-rainbow-drop absolute [${drop.dropColor}] rounded-b-full h-[120px] z-10`}
          style={{
            width: drop.width,
            left: drop.posX,
            animationDelay: drop.delay + 's',
            background: `linear-gradient(transparent, ${drop.dropColor})`,
            opacity: drop.opacity,
          }}
          key={i}
        />
      </>)}
    </div>
  </>)
}

export default RainbowRain