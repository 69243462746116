import React, { useEffect, useState } from 'react'

interface Props {
  vote: 0 | 1
  upvotes: number
  downvotes: number
}

export function Voted(props: Props) {
  const { vote, upvotes, downvotes } = props
  const majority = upvotes === downvotes ? null : upvotes > downvotes ? 1 : 0
    

  function voteToString(vote: 0 | 1) {
    return vote === 0 ? 'down' : 'up'
  }

  return (<>
    <div className='flex flex-col text-text-primary font-semibold text-lg my-2 grow-into-view'>
      <p className={`mx-auto px-4`}>
        <span>Most people</span>
        <span className={majority === vote ? 'text-hype-100' : 'text-hate-100'}>
          {majority === vote ? ' agree ' : ' disagree '}
        </span>
        <span>with you!</span>
      </p>
      
      <p className={`mx-auto px-4`}>
        <span>
          {majority !== vote && 'Just '}
        </span>
        <span className={majority === vote ? 'text-hype-100' : 'text-hate-100'}>
          {Math.round((vote === 0 ? downvotes : upvotes) / (upvotes + downvotes) * 100)}% {' '}
        </span>
        <span>
          of people voted {voteToString(vote)}
        </span>
      </p>
    </div>
  </>)
}
