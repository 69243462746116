import React, { createContext, useEffect, useState, useContext } from 'react'
import { useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import {
  doc,
  DocumentData,
  DocumentSnapshot,
  getDoc,
  getDocFromCache,
  getDocFromServer,
} from 'firebase/firestore'

import { Fireworks, useFireworks } from 'fireworks-js/dist/react'

import { loadFull } from 'tsparticles'
import type { Engine } from 'tsparticles-engine'

import Result from '../components/Result'
import quiz from '../types/quiz'
import userDataType from '../types/userData'
import { Question } from '../components/Question'
import { auth, db, functions } from '../firebaseConfig'
import AnimationContextType, { GlobalAnimation } from '../types/animation'
import ConfettiContext from '../ConfettiContext'
import { backgroundsObject } from '../data/backgrounds'
import { httpsCallable } from 'firebase/functions'
import { Vote } from '../types/vote'
import { quizData } from '../../functions/src/submitQuizAnswers'
import RainbowRain from '../components/RainbowRain'

export const animationContext = createContext<AnimationContextType>({} as any)

function QuizPage() {
  // Attempt to get quiz from react-router-dom state / cache first
  const location = useLocation()
  const state = location?.state as
    | { quiz: quiz; userData: userDataType | null }
    | undefined

  const [quiz, setQuiz] = useState<quiz | undefined>(state?.quiz)
  const [userData, setUserData] = useState<userDataType | null | undefined>(
    state?.userData
  )
  const [score, setScore] = useState(0)
  const [answered, setAnswered] = useState(0)
  const [answers, setAnswers] = useState<Vote[]>([])
  const [animation, setAnimation] = useState<GlobalAnimation>(GlobalAnimation.None)
  const [quizFinished, setQuizFinished] = useState(false)
  const confetti = useContext(ConfettiContext)

  function confettiCallback() {
    if (window.location.pathname.includes('/quiz/')) {
      confetti.addConfetti({
        emojis: ['🎉', '🎊', '💎', '✅', '💥', '🔥'],
        emojiSize: 40,
        confettiNumber: 125,
      })
    } else {
      clearInterval(confettiInterval)
    }
  }

  let confettiInterval: NodeJS.Timer

  useEffect(() => {
    switch (animation) {
      case GlobalAnimation.Confetti:
        confettiCallback()
        confettiInterval = setInterval(confettiCallback, 4000)
        setAnimation(GlobalAnimation.None)
        break
        case GlobalAnimation.Fireworks:
          setFireworksEnabled(true)
          setAnimation(GlobalAnimation.None)
        break
      case GlobalAnimation.RainbowRain:
        break
      default:
        // return instead of break to avoid setting background
        return
    }
    window.document.body.style.backgroundImage = `url(${backgroundsObject.random()})`
  }, [animation])

  useEffect(() => {
    (async () => {
      if (answered === quiz?.questions?.length) {
        setAnimation(
          // +1 is to avoid None animation, 3 is the max animation index (See GlobalAnimation enum)
          Math.floor(Math.random() * 3) + 1
        )
        setTimeout(() => {
          setQuizFinished(true)

          // Prevent scrolling when quiz ends
          const bodyClasses = document.body.classList
          bodyClasses.add('h-full')
          bodyClasses.add('overflow-hidden')
        }, 3000)

        if (auth.currentUser) {
          const submitQuizAnswers = httpsCallable<quizData>(functions, 'submitQuizAnswers')
          await submitQuizAnswers({ quizId: quiz.id, userAnswers: answers })
        }
      }
    })()
  }, [answered])

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    (async () => {
      if (!state) {
        const pathSegments = window.location.pathname.split('/')
        const quizRef = doc(
          db,
          'sites',
          pathSegments[pathSegments.length - 1] as string
        )

        let quizDoc: DocumentSnapshot<DocumentData>
        try {
          // getDocFromCache errors if it doesn't exist in cache
          quizDoc = await getDocFromCache(quizRef)
        } catch {
          quizDoc = await getDocFromServer(quizRef)
        }

        if (quizDoc?.exists()) {
          setQuiz({ ...quizDoc.data(), id: quizDoc.id } as quiz)
        }
      }

      // specifically checking for undefined, null means logged out, undefined means unknown
      if (state?.userData === undefined) {
        const uid = auth?.currentUser?.uid
        if (uid) {
          const userRef = doc(db, 'users', uid ?? 'User is signed out')
          const userSnap = await getDoc(userRef)
          setUserData({ ...userSnap.data(), uid } as userDataType)
        }
      }
    })()
  }, [])

  const {
    setOptions: setFireworksOptions,
    options: fireworkOptions,
    enabled: fireworksEnabled,
    setEnabled: setFireworksEnabled,
  } = useFireworks({
    initialStart: false,
    initialOptions: {
      rocketsPoint: {
        min: 0,
        max: 100,
      },
      hue: {
        min: 0,
        max: 360,
      },
    },
  })

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine)
  }, [])

  return (
    <>
      <main
        className={`flex flex-col text-center text-white snap w-screen ${
          answered === quiz?.questions?.length && 'overflow-y-hidden'
        }`}
      >
        <div className="flex flex-col w-1/3 mx-auto snap-center">
          {/* <h1 className='text-2xl font-semibold mt-4'>
          {quiz?.title}
        </h1> */}

          {/* <h3 className='text-lg font-semibold mt-4'>
          Share on 
          <br />
          <div className='flex justify-evenly font-semibold'>
            <a
              href={`https://twitter.com/intent/tweet?text=I found this cool quiz look ${window.location.href}`}
              target='_blank'
              rel='noopener noreferrer'
            >
              Twitter
            </a>
            <button
              onClick={(e) => {
                navigator.clipboard.writeText(window.location.href)
                const target = e.currentTarget
                const beforeText = 'Copy Link'
                target.innerText = 'Copied!'
                setTimeout(() => {
                  target.innerText = beforeText
                }, 1000)
            }}
              className='font-semibold'
            >
              Copy Link
            </button>
          </div>
        </h3> */}
        </div>

        <div className="h-max">
          <animationContext.Provider
            value={{
              state: [animation, setAnimation],
              fireworks: { setFireworksOptions, setFireworksEnabled },
            }}
          >
            {quiz?.questions?.map((question, idx) => (
              <Question
                {...question}
                live={true}
                key={idx}
                userData={userData ?? null}
                answer={parseInt(question.answer as unknown as string) as 0 | 1}
                onAnswer={setAnswered}
                setScore={setScore}
                setAnswers={setAnswers}
                currentQuestion={answered === idx && idx !== 0}
              />
            ))}
          </animationContext.Provider>
        </div>
        {quizFinished && quiz && (
          <Result
            title={quiz?.title}
            src={quiz?.endImage ?? quiz?.image ?? ''}
            score={score}
            totalQuestions={quiz?.questions?.length}
          />
        )}
      </main>

      <Fireworks
        enabled={fireworksEnabled}
        options={fireworkOptions}
        className="fixed h-screen w-screen pointer-events-none top-0 left-0 z-10"
      />

      {animation === GlobalAnimation.RainbowRain && <RainbowRain />}

      {/* {(animation === GlobalAnimation.Tunnel ||
        animation === GlobalAnimation.Fountain) && (
        <Particles
          className="fixed h-screen w-screen pointer-events-none top-0 left-0 -z-10 bg-none"
          options={(() => {
            if (animation === GlobalAnimation.Tunnel) return getTunnel()
            if (animation === GlobalAnimation.Fountain) return getFountain()
            return undefined
          })()}
          init={particlesInit}
        />
      )} */}
    </>
  )
}

export default QuizPage
