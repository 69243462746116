import React, { useEffect, useState } from 'react'
import { Navigate, Outlet } from "react-router-dom"
import { useAuthState, } from 'react-firebase-hooks/auth'
import { doc, getDoc } from "firebase/firestore"
import { auth, db } from '../firebaseConfig'
import { ProtectionLevel } from '../types/protectionLevel'

interface Props {
	protectionLevel: ProtectionLevel
}

function ProtectedPage(props: Props) {
	const { protectionLevel } = props
	const [user, loading] = useAuthState(auth)

	const [allowStatus, setAllowStatus] = useState<boolean | null>(null)

	async	function findUser() {
		// return true
		const adminRef = doc(db, 'admins', `${user ? user.uid : 'no'}`)
		const adminSnap = await getDoc(adminRef)
		return adminSnap.exists() ? true : false
	}

	useEffect(() => {
		if (loading) return

		async function getAdmin() {
			setAllowStatus(!!user && await findUser())
			return null
		}
		if (protectionLevel === ProtectionLevel.Admin) {
			getAdmin()
		} else {
			setAllowStatus(!!user)
		}

	}, [loading])

	
  return (<>
	  {loading && <p className='text-white font-medium text-3xl'>Loading...</p>}

	  {(allowStatus === true) && <Outlet />}
		{(allowStatus === false) && <Navigate to='/' />}
	</>)
}

export { ProtectedPage }