import { collection, doc, query, where } from "firebase/firestore"
import { useDocumentData } from "react-firebase-hooks/firestore"
import { auth, db } from "../firebaseConfig"
import userDataType from "../types/userData"

export default function useUserData() {
  const usersRef = doc(db, `users/${auth.currentUser ? auth.currentUser.uid : 'User is signed out'}`)
  const [userData] = useDocumentData(usersRef, { idField: 'id' })

  return userData as unknown as userDataType | undefined
}