import React from 'react'

interface Props {
  children: React.ReactNode
}

function NeumorphicCard(props: Props) {
  const { children } = props

  return (
    <div className='pt-12 mx-4 mb-4 md:mb-0 flex flex-col align-middle items-center m-auto my-6 rounded-[2.5rem] duration-300 shadow-neu bg-gradient-to-r to-accent-primary/0 from-accent-primary/50/0 text-white font-Fira snap-center'>
      {children}
    </div>
  )
}

export default NeumorphicCard