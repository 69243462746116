import React, { useEffect, useState } from 'react'

import { doc, setDoc, serverTimestamp, collection, } from 'firebase/firestore'
import { getDownloadURL, getMetadata, ref, uploadBytesResumable } from 'firebase/storage'
import { db, auth, storage } from '../firebaseConfig'
import InputField from './InputField'
import quizFields from '../data/quizFields'
import SiteCard from './SiteCard'
import RichTextEditor from './RichTextEditor'
import { Site, SiteWithId } from '../types/business'

export function Editor(props: SiteWithId) {
  const { id, hasPoll: initialHasPoll } = props
  const [formValue, setFormValue] = useState<SiteWithId>({} as SiteWithId)
  const [hasPoll, setHasPoll] = useState(false)
  const [mediaProgress, setMediaProgress] = useState(0)

  useEffect(() => {
    if (props) {
      setFormValue(props as unknown as SiteWithId)
      setHasPoll(initialHasPoll ?? false)
    } else {
      setFormValue({} as SiteWithId)
    }
  }, [props])

  useEffect(() => {
    console.log('formValue', formValue)
  }, [formValue])

  async function sendPoll(e: React.FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault()
    console.log(e)
    const postRef = collection(db, 'sites')

    if (!auth.currentUser) return

    if (formValue?.category) {
      formValue.category = formValue.category.toLowerCase()
      formValue.category = formValue.category.replace(' ', '')
      formValue.categories = formValue.category.split(',')
    }
    
    try {
      const totalVotes = Math.floor(Math.random() * 150) + 50
      const upvotes = Math.floor(Math.random() * totalVotes - 40) + 40
      const downvotes = totalVotes - upvotes

      setDoc((id ? doc(db, `sites/${id}`) : doc(postRef)), {
        ...formValue,
        createdAt: serverTimestamp(),
        upvotes,
        downvotes,
      }).then(() => {
        window.location.reload()
      })

    } catch (error) {
      console.log(error)
    }
  }

  function adjustFormValue(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement | any>, name: string, subDir?: keyof Site, idx?: number) {
    const { value } = e.target

    const file = e.target.files ? e.target.files[0] : null
  
    if (file) {
      mediaHandler(e)
    }

    if (e.target.type === 'checkbox') {
      // @ts-ignore
      setFormValue({ ...formValue, [name]: e.target.checked })
      return
    }

    if (subDir !== undefined && typeof formValue[subDir] === 'object' && idx !== undefined) {
      const formField = formValue[subDir]

      // If the type isn't an array, we can add it no problem as a simple key/value pair
      if (typeof formField === 'string' || typeof formField === 'number') {
        setFormValue({
          ...formValue,
          [subDir]: value,
        })
        return
      }
      
      // If the adjusted value is in an array, we need to update the array
      setFormValue({...formValue, [subDir]: (() => {
        const arr = formField
        // @ts-ignore
        arr[idx] = value
        return arr
      })()})
    } else {
      setFormValue({ ...formValue, [name]: value })
    }
  }

  async function mediaHandler(e: React.ChangeEvent<HTMLInputElement>) {

    async function getUniqueName(): Promise<string> {
      const date = Date.now()
      const random = Math.random()
      const imageRef = ref(storage, `media/${date}-${random}`)
      const imageExists = await getMetadata(imageRef).catch(() => false)
      if (!imageExists) {
        return `${date}-${random}`
      } else {
        return getUniqueName()
      }
    }

    if (e.target.files) {
      const file = e.target.files[0]
      const storageRef = ref(storage, `media/${await getUniqueName()}`)
      const uploadTask = uploadBytesResumable(storageRef, file)
      
      uploadTask.on('state_changed', (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        setMediaProgress(progress)
      }, 
      (error) => {
        console.log(error)
      }, 
      // When upload is complete
      async () => {
        const downloadURL = await getDownloadURL(storageRef)
        if (file.type.startsWith('image/')) {
          setFormValue({ ...formValue, image: downloadURL })
        } else if (file.type.startsWith('video/')) {
          setFormValue({ ...formValue, video: downloadURL })
        }
      })
    }
  }

	return (<>
  <meta name="robots" content="noindex" />
  <div>
    {/* TODO: add some fancy large text here */}
    {/* <p className='text-center text-white font-semibold text-3xl'></p> */}
    <form onSubmit={sendPoll} className='flex justify-center align-middle flex-col h-auto m-4'>
      <div className='flex flex-col mx-auto text-center w-full'>
        {formValue && quizFields.map((field, idx) => 
          <InputField 
            field={field} 
            adjustFormValue={adjustFormValue} 
            formValue={formValue ?? {}} 
            mediaProgress={mediaProgress} 
            key={idx}
          />)
        }
      </div>
 
        {(!id || (formValue.description || !props.description)) &&
          <RichTextEditor
            currentContent={formValue.description}
            onChange={(e) => setFormValue((current) => {
              return { ...current, description: e }
            })}
          />
        }
        
      <div className='flex'>
        <p className='text-primary-200 font-semibold text-black'>
          Has a poll
        </p>

        <input 
          className='w-min bg-primary-100 btn'
          type='checkbox' 
          name='Is poll'
          checked={hasPoll}
          onChange={(e) => {
            setHasPoll(e.target.checked)
            adjustFormValue(e, 'hasPoll')
          }}
        />
      </div>

      <button type='submit' className='btn bg-hype-100 w-min ml-auto'>Publish</button>
    </form>
    <div className='mx-auto flex justify-center'>
      <SiteCard
          businessData={formValue ?? {}}
          isPinned={false}
          setFavorites={(x) => {}}
      />
    </div>
  </div>
	</>)
}
