import React, { useState, useEffect, useRef } from 'react'

import { Voted } from './Voted'
import { VoteButtons } from './VoteButtons'

import userDataType from '../types/userData'

import LazyLoad from 'react-lazyload'
import question from '../types/question'
import { Vote } from '../types/vote'
import SquareImage from './SquareImage'

type Props = question & {
  userData: userDataType | null 
  live: boolean
  currentQuestion: boolean
  onAnswer: React.Dispatch<React.SetStateAction<number>>
  setScore: React.Dispatch<React.SetStateAction<number>>
  setAnswers: React.Dispatch<React.SetStateAction<Vote[]>>
}

export function Question(props: Props) {
  const { title, description, image, video, userData, id, answer, answerText, onAnswer, setScore, setAnswers, currentQuestion, learnMoreLink } = props
  
  const [localAnimation, setLocalAnimation] = useState('')

  const [vote, setVote] = useState<0 | 1 | null>(null)
  const [height, setHeight] = useState(200)

  const questionRef = useRef<HTMLDivElement>(null)

  
  // finds if the user has voted on this
  // useEffect(() => {
  //   if (userData) {
  //     for (let i = 0; i < userData.previouslyVotedOnSites.length; i++) {
  //       if (userData.previouslyVotedOnSites[i].id === id) {
  //         break
  //       }
  //     } 
  //   }
  // }, [userData, id])

  useEffect(() => {
    if (vote !== null) {
      onAnswer((n) => n + 1)
      setScore((score) => vote === answer ? score + 1 : score)
      setAnswers((answers) => [...answers, vote])
    }
  }, [vote])

  // useEffect(() => {
  //   if (currentQuestion === true && vote === null) {
  //     setTimeout(() => {
  //       questionRef.current?.scrollIntoView({ behavior: 'smooth' })
  //     }, 3000)
  //   }
  // }, [currentQuestion])

  return (<>
    <div 
      className={`flex flex-col items-center justify-center mx-auto mb-[20vh] mt-20 sm:w-11/12 max-w-2xl duration-300 snap-center max-h-[80vh] h-screen sm:h-max ${localAnimation}`}
      ref={questionRef}
    >
    <div className={`w-full sm:shadow-outer-glow rounded-2xl sm:py-1 bg-background-200 ${vote === answer ? 'border-hype-100 shadow-hype-400' : vote !== answer && vote !== null ? 'border-hate-100 shadow-hate-300' : 'border-white/25'} sm:border-4 `}>
      {/* <p className='text-white text-center font-semibold text-xl mt-2 mx-auto'>{title?.toUpperCase()}</p> */}

      <div className={`mx-4 mb-4 md:mb-0 flex flex-col align-middle`}>
        <p className='text-accent-primary font-semibold text-2xl md:text-3xl mx-auto text-center'>
          {title}
        </p>

        <div className='relative'>
            {image && (
              <SquareImage
                src={image}
                alt={title}
                onLoad={(el) => setHeight(el.currentTarget.height + 12 + 16)}
              />
            )}

            {video && (
              <LazyLoad 
              offset={100} 
              height={height} 
              unmountIfInvisible={true}
              >
                <video
                  src={video}
                  width={'100%'}
                  height={'100%'}
                  controls={true}
                  autoPlay={true}
                  loop={true}
                  muted={true}
                  className='rounded-3xl mx-auto my-3 text-white max-h-[80vh]'
                  onLoadedData={(el) => setHeight(el.currentTarget.height + 16)}
                  />
              </LazyLoad>
            )}
          <div className={`absolute bottom-[5%] flex flex-col justify-center align-middle h-min mx-auto w-full`}>
            {/* { vote !== null ? 
                // <Voted 
                //   vote={vote} 
                //   answer={answer} 
                //   answerText={answerText} 
                //   learnMoreLink={learnMoreLink}
                // /> 
              // : <></>
                // <VoteButtons 
                //     userData={userData}
                //     setVote={setVote}
                //     setLocalAnimation={setLocalAnimation}
                //   // {...props, setVoteState: setVote, voteState: vote, }
                // />  
            } */}
          </div>
        </div>
      </div>

      <div className={`flex flex-col font-semibold justify-center align-middle h-min mx-auto`}>

        <p className='text-white text-xl mx-auto px-4'>
          {description}
        </p>

        {vote !== null && (<>
          <p className='mx-auto text-xl'>
            {answerText}
          </p>
          {learnMoreLink && (
            <a 
              href={learnMoreLink} 
              target='_blank'
              rel='noopener noreferrer'
              className='mx-auto underline text-slate-600'
            >
              Learn More
            </a>
          )}
        </>
        )}

      </div>
      </div>
    </div>
  </>
  )
}
