import React, { useEffect, useRef } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import { Link } from 'react-router-dom'
import { auth } from '../firebaseConfig'
import { SignInButton, SignOutButton } from './Authentication'

interface Props {
  score: number
  totalQuestions: number
  src: string
  title: string
}

function Result(props: Props) {
  const { score, totalQuestions, src, title } = props

  const [user, loading] = useAuthState(auth)

  const percentage = parseInt(((score / totalQuestions) * 100).toFixed(0))
  const message =
    percentage > 75
      ? 'Pretty good!'
      : percentage > 50
      ? 'Not bad!'
      : 'Could be better...'

  const resultRef = useRef<HTMLSpanElement>(null)

  return (
    <>
      <div
        className="fixed w-screen h-screen top-0 left-0 z-10 bg-no-repeat bg-center bg-cover"
        style={{ backgroundImage: `url(${src})` }}
      >
        <div className="flex flex-col justify-around h-full w-full max-w-3xl mx-auto my-auto text-white font-semibold text-2xl">
          <div>
            <h1 className="mb-4">{title}</h1>

            {/* <img
              src={src}
              className="rounded-2xl mx-auto"
            /> */}

            {/* <span
              className={` rounded-2xl mt-10 mb-10 p-4 ${
                percentage > 50
                  ? 'bg-hype-100 shadow-hype-400'
                  : 'bg-hate-100 shadow-hate-300'
              }`}
              ref={resultRef}
            > */}
              <h1>Result</h1>
              {score} / {totalQuestions}
              <p>
                That's {percentage}%, {message}
              </p>
            {/* </span> */}
          </div>
          
          <div className='flex flex-col'>
            <button
              onClick={() => { 
                window.location.reload()
              }}
              className="cursor-pointer font-semibold btn bg-hype-100/25 hover:bg-hype-100 w-max mx-auto mb-4"
            >
              Restart
            </button>
            
            <a
              href='/'
              className='font-semibold btn bg-hype-100/25 hover:bg-hype-100 w-max mx-auto mb-4'
            >
              Home
            </a>

            {!loading &&
              (
              user ? 
                <SignOutButton className={'font-semibold btn bg-hype-100/25 hover:bg-hype-100 w-max mx-auto my-0'}/>
              : 
                <SignInButton className={'font-semibold btn bg-hype-100/25 hover:bg-hype-100 w-max mx-auto my-0'} />
            )
            }
          </div>

          <div className='w-full'>
            <p>
              Share to
            </p>

            <div className='flex justify-evenly w-11/12 mx-auto flex-wrap'>
              <a
                href={`https://twitter.com/intent/tweet?text=I found this cool quiz look ${window.location.href}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/4f/Twitter-logo.svg/2491px-Twitter-logo.svg.png'
                  width={40}
                  height={40}
                />
              </a>
                
              <a
                href={`https://facebook.com/sharer/sharer.php?u=I found this cool quiz look ${window.location.href}`}
                target='_blank'
                rel='noopener noreferrer'
              >
                <img
                  src='https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Facebook_f_logo_%282021%29.svg/150px-Facebook_f_logo_%282021%29.svg.png'
                  width={40}
                  height={40}
                />
              </a>
                
              <a
                href={`https://pinterest.com/pin/create/button/?url=${window.location.href};media=${src};description=I found this cool quiz!`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src='https://upload.wikimedia.org/wikipedia/commons/0/08/Pinterest-logo.png'
                  width={40}
                  height={40}
                />
              </a>
            </div>
              {/* <button
                onClick={(e) => {
                  e.preventDefault()
                    
                    
                  const data: ShareData = {
                    url: window.location.href,
                    text: `I got ${score} out of ${totalQuestions} questions correct on this quiz. Try it out!`,
                  }
                  
                  if (navigator.canShare(data)) {
                    navigator.share(data)
                  } else {
                    console.log('Web Share API is not supported')
                  }
                }
              }
                className='font-semibold'
              >
                Share
              </button> */}

              <button
                onClick={(e) => {
                  navigator.clipboard.writeText(window.location.href)
                  const target = e.currentTarget
                  const beforeText = 'Copy Link'
                  target.innerText = 'Copied!'
                  setTimeout(() => {
                    target.innerText = beforeText
                  }, 1000)
              }}
                className='font-semibold'
              >
                Copy Link
              </button>
            </div>
        </div>
      </div>
    </>
  )
}

export default Result
