import React, { useEffect, useState } from 'react'

import { queryCategory } from './MainPage' 
import { useCollection } from 'react-firebase-hooks/firestore'
import { Editor } from '../components/Editor'
import quiz, { quizWithID } from '../types/quiz'
import { doc, getDoc, getDocs, setDoc,  } from 'firebase/firestore'
import { db } from '../firebaseConfig'
import { SiteWithId } from '../types/business'

export function AdminPage() {
  const [showPreviousPosts, setShowPreviousPosts] = useState(false)
  const [selectedPost, setSelectedPost] = useState<SiteWithId | null>(null)

  const [ postsSnapshot ] = useCollection(queryCategory(null))
  const [ posts, setPosts ] = useState<SiteWithId[]>([])

  useEffect(() => {
    if (postsSnapshot) {
      const postsData = postsSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id } as SiteWithId))
      setPosts(postsData as unknown as SiteWithId[])
    }
  }, [postsSnapshot])

  function handlePostClick(idx: number | null) {
    setShowPreviousPosts(false)
    console.log(idx)
    if (posts !== null) {
      setSelectedPost(idx !== null ? posts[idx] as unknown as SiteWithId : null)
    }
  }

	return (<>
    <div className='text-white self-center font-semibold flex flex-col'>
      <button 
          className='btn bg-hype-300 w-max mx-auto my-4 py-2 text-white font-semibold'
          onClick={() => {
            setShowPreviousPosts(!showPreviousPosts)
          }}
        >
        Edit Previous Posts
      </button>

      {showPreviousPosts && <>
          <div className='text-white text-3xl font-semibold'>
            Previous Posts
            <br />
            <button 
              className='btn bg-hype-300 text-white font-semibold my-6'
              onClick={() => {
                handlePostClick(null)
              }}
            >
              Edit New Post
            </button>
              
            {posts && posts?.map((post, idx) => 
              <li>
                <button
                  className='bg-hype-300 text-white text-2xl font-semibold btn'
                  onClick={() => handlePostClick(idx)}
                >
                  {post?.subtitle ?? 'Untitled'}
                </button>
              </li>
            )}
          </div>
        </>
      }
    <Editor { ...selectedPost as unknown as SiteWithId } />
    </div>
    {/* <button className='btn'
      onClick={async () => {
        const currentSitesDocs = await getDocs(queryCategory(null))
        const currentSites = currentSitesDocs.docs.map(doc => doc.data() as quiz)
        // console.log(currentSites[0])
        const newSites = currentSites.map(quiz => {
          if (quiz?.category && !quiz?.categories) {
            quiz.category = quiz.category.toLowerCase()
            quiz.categories = quiz.category.split(',')
          }
          // console.log('quiz', quiz.categories)
          return quiz
        })

        newSites.forEach(async (q, i) => {
          try {
            const ref = doc(db, 'sites', currentSitesDocs.docs[i].id)
            await setDoc(ref, q)
          } catch (e) {
            console.log(e)
          }
        })
      }}
    >
      switch categories
    </button> */}
	</>)
}

