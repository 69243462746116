import { httpsCallable } from 'firebase/functions'
import React, { Dispatch } from 'react'
import { functions } from '../firebaseConfig'
import useUserData from '../hooks/userData'


interface Props {
  setVote: Dispatch<React.SetStateAction<0 | 1 | null>>
  vote: 0 | 1 | null
}

export function VoteButtons(props: Props) {
  const { setVote, vote } = props
  
  const userData = useUserData()
  // const [isSafari, setIsSafari] = useState(false)
  
  // useEffect(() => {
  //   let chromeAgent = navigator.userAgent.indexOf("Chrome") > -1
  //   let safariAgent = navigator.userAgent.indexOf("Safari") > -1

  //   // Discard Safari since it also matches Chrome
  //   if ((chromeAgent) && (safariAgent)) safariAgent = false
  //   if (safariAgent) {
  //     setIsSafari(true)
  //   }
  // }, [])

  function castVote(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, v: 0 | 1) {
    // Eventually the vote will be sent to the server
    // if (userData) {
    // const sendVote = httpsCallable(functions, 'vote')
    //   sendVote({...props, vote: v})
    // }
    e.stopPropagation()
    setVote(v)
  }

  return (<>
    <h3 className='text-lg font-medium'>
      Vote on this idea!
    </h3>
    <div className="flex flex-row mx-auto">
      <button 
        className={`arrow rotate-180 mr-2 ${vote === 0 && 'arrow-dark'}`}
        onClick={(e) => castVote(e, 0)}
      >
      </button>
      <button 
        className={`arrow ${vote === 1 && 'arrow-dark'}`}
        onClick={(e) => castVote(e, 1)}
      >
      </button>
    </div>
  </>)
}
