import { GoogleAuthProvider, signInWithPopup, signOut } from "firebase/auth"
// import  { collection, doc, setDoc, getDoc, serverTimestamp} from 'firebase/firestore'

import { auth } from '../firebaseConfig'

import React from 'react'

const provider = new GoogleAuthProvider()

export async function signedIn(cred: any) {
    // const uid = cred.user.uid
    // const coll = collection(db, 'users')
    // const userRef = doc(db, 'users', uid)
    // const userDocSnap = await getDoc(userRef)
    
    // if (!userDocSnap.exists()) {
    //   return setDoc(doc(coll, `${uid}`), {
    //     displayName: cred.user.displayName,
    //     email: cred.user.email,
    //     photoURL: cred.user.photoURL,
    //     createdAt: serverTimestamp(),
    //     uid: uid,
    //     votes: [],
    //   })
    // }
}

export function signInWithGoogle() {
    signInWithPopup(auth, provider).then((cred) => {
        signedIn(cred)
    }).catch((e) => {
        // console.log(e.code)
    })
}
  
function SignInButton(props: { className?: string }) {
    return (
        <button className={`flex flex-col text-center ml-0 pl-0 mt-4 btn font-Fira hover:bg-hype-200/0 my-auto ${props.className}`} onClick={signInWithGoogle}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="2rem" height="2rem" fill="transparent" stroke="white" stroke-width="2" className="self-center">
              <circle cx="12" cy="8" r="4"/>
              <path d="M4 20c0-4 4-7 8-7s8 3 8 7"/>
            </svg>
            <p className="mt-1 text-lg">
                Sign In
            </p>
        </button>
    )
}

function SignOutButton(props: { className?: string }) {
    return (
        <button className={`flex flex-col text-center ml-0 pl-0 mt-4 btn font-Fira hover:bg-hate-200/0 my-auto ${props.className}`} onClick={() => signOut(auth)}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="2rem" height="2rem" fill="transparent" stroke="white" stroke-width="2" className="self-center">
              <circle cx="12" cy="8" r="4"/>
              <path d="M4 20c0-4 4-7 8-7s8 3 8 7"/>
            </svg>

            {/* <button
              onClick={() => window.location.href = '/history'}
              className='btn bg-accent-primary hover:bg-hype-200 w-max'
            >
              History
            </button> */}
        
            <p className="mt-1 text-lg">
                Sign Out
            </p>
        </button>
    )
}

export { SignInButton, SignOutButton }