import React from 'react'
import Field from '../types/field'
import { Site } from '../types/business'

type Props = {
  field: Field
  formValue: Site
  questionIdx?: number
  adjustFormValue: (e: React.ChangeEvent<HTMLInputElement>, fieldName: string, subDir?: keyof Site, idx?: number) => void
  mediaProgress: number
}

function AdminInputField(props: Props) {
  const { field, formValue, adjustFormValue, questionIdx, mediaProgress} = props

  return (<>
    <input
      key={field.label}
      placeholder={field.label}
      value={formValue[field.value as keyof Site] as string}
      required={field.required}
      spellCheck={field.spellCheck}
      type={field.type}
      // accept={field.accept || ''}
      onChange={(e) => adjustFormValue(e, field.value, undefined, questionIdx)}
      className={`bg-background-200 mx-2 my-5 p-2 rounded-lg border-slate-600 border-4 text-[#9ca3af] file:text-white file:border-0
      ${/*field.name === 'file' ? 'text-transparent' : ''*/''}
      `}
    />

    {field.type === 'file' && (mediaProgress !== 0 && mediaProgress !== 100) && 
      <progress 
        className='w-11/12 h-8 rounded-xl overflow-hidden' 
        value={mediaProgress} 
        max='100' 
      /> 
    }
  </>)
}

export default AdminInputField