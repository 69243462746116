import React, { useEffect } from 'react'
import { BrowserRouter, Navigate, Route, Routes, } from 'react-router-dom'

import { MainPage } from './pages/MainPage'
import { AdminPage } from './pages/AdminPage'
import QuizPage from './pages/QuizPage'
import HistoryPage from './pages/HistoryPage'

import { ProtectedPage } from './components/ProtectedPage'

import { ProtectionLevel } from './types/protectionLevel'
import { ConfettiProvider } from './ConfettiContext'
import { useVideoAutoplay } from './hooks/videoAutoplay'

function App() {
	useVideoAutoplay()

	useEffect(() => {
		// const backgroundImage = 'https://thumbs.gfycat.com/AgitatedIdolizedHarborporpoise-size_restricted.gif'
		// const bodyElement = document.querySelector('body')
		// if (bodyElement?.style) {
		// 	bodyElement.style.backgroundImage = `url(${backgroundImage})`
		// 	bodyElement.style.backgroundSize = 'cover'
		// }
	}, [])

  return (<>
	<ConfettiProvider>
		<BrowserRouter>
			<Routes>
				<Route path='/' element={<MainPage />} />
					
				<Route path='/quiz'>
					<Route path='/quiz' element={<Navigate to='/404' />} />
					<Route path=':quizId' element={<QuizPage />} />
				</Route>

				<Route element={<ProtectedPage protectionLevel={ProtectionLevel.LoggedIn} />}>
					<Route path='/history' element={<HistoryPage />} />
				</Route>
					
				<Route element={<ProtectedPage protectionLevel={ProtectionLevel.Admin} />}>
					<Route path='/admin' element={<AdminPage /> } />
				</Route>
					
				<Route path='*' element={<div className='text-white font-bold text-5xl mt-4'> URL Not Found</div>} />
			</Routes>
		</BrowserRouter>
	</ConfettiProvider>
  </>)
}

export default App
