import React, { useEffect, useCallback } from 'react'
import { useEditor, EditorContent, generateHTML, JSONContent } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Link from '@tiptap/extension-link'

interface Props {
  currentContent?: JSONContent
  onChange: (content: JSONContent) => void
}

function useConditionalStyling(condition: boolean) {
  return `btn w-max ${condition ? 'bg-accent-primary text-white' : 'bg-accent-primary/30 text-white'}`
}

function RichTextEditor(props: Props) {
  const { currentContent, onChange } = props

  useEffect(() => {
    console.log('currentContent', editor?.isEmpty ? 'empty' : 'not empty')
    if (!currentContent && !editor?.isEmpty) {
      editor?.commands.deleteRange({from: 0, to: editor.getCharacterCount()})
    }

  }, [props])

  const editor = useEditor({
    editorProps: {
      attributes: {
        class: 'bg-background-100 my-5 p-2 rounded-lg border-slate-50 shadow-xl font-semibold text-accent-primary resize-none',
      },
    },
    extensions: [
      StarterKit.configure({
        bulletList: {
          HTMLAttributes: {
            className: 'description-bullet-point',
          },
        }
      }),
      Link.configure({
        protocols: ['http', 'https', 'mailto', 'tel'],
        HTMLAttributes: {
          class: 'description-link'
        },
        openOnClick: false,
      })
    ],
    content: currentContent
  })

  useEffect(() => {
    editor?.on('update', (e) => {
      onChange(e.editor.getJSON())
    })
  }, [editor])

  const setLink = useCallback(() => {
    if (!editor) return
    const previousUrl = editor.getAttributes('link').href
    const url = window.prompt('URL', previousUrl)

    // cancelled
    if (url === null) {
      return
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink()
        .run()

      return
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url })
      .run()
  }, [editor])

  return (<>
    <span className='flex'>
      {/* Adding type=button is CRITICALLY important btw */}
      <button
          type='button'
          onClick={() => editor?.chain().focus().toggleBold().run()}
          disabled={
            !editor?.can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          className={useConditionalStyling(!editor?.isActive('bold'))}
        >
          Bold
      </button>
      
      <button
        type='button'
        onClick={setLink}
        className={useConditionalStyling(!editor?.isActive('link'))}
      >
        Add Link
      </button>
      <button
        type='button'
        onClick={() => editor?.chain().focus().unsetLink().run()}
        className={useConditionalStyling(!!editor?.isActive('link'))}
      >
        Remove Link
      </button>
      <button
        type='button'
        onClick={() => editor?.chain().focus().toggleBulletList().run()}
        className={useConditionalStyling(!editor?.isActive('bulletList'))}
      >
        Bullet Point
      </button>
    </span>
    <EditorContent
      editor={editor}
    />
  </>)
}

export default RichTextEditor