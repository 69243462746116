import React, { useEffect } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import Header from '../components/Header'

import { Quiz } from '../components/Quiz'
import { auth } from '../firebaseConfig'
import useUserData from '../hooks/userData'
import answerData from '../types/vote'

function HistoryPage() {
  const [user, loading] = useAuthState(auth)
  const userData = useUserData()

  function getHistoryText(quiz: answerData): string[] {
    if ((quiz?.previousAnswers?.length || 0) === 0) {
      return ['']
    }
    
    const previousAnswers = quiz.previousAnswers

    const mostRecentAnswer = previousAnswers[(previousAnswers.length - 1) ?? 0]?.score ?? 0
    const firstAnswer = previousAnswers[0]?.score ?? 0
    const quizLength = quiz.postData.questions.length

    // console.log('most recent answer', previousAnswers[(quiz?.previousAnswers?.length - 1) ?? 0]?.votes)
    // console.log('answers', quiz.postData.questions.map(q => q.answer))
  
  
    if (previousAnswers.length === 1) {
      return [`You scored: ${mostRecentAnswer} / ${quizLength}, ${(mostRecentAnswer / quizLength * 100).toFixed(0)}%!`]
    } 
  
    return [
      `You've taken this quiz ${previousAnswers.length} times before`,
      `The first time, you got ${firstAnswer}/${quizLength} correct, which is ${(firstAnswer / quizLength * 100).toFixed(0)}%!`,
      `The last time, you scored ${mostRecentAnswer}/${quizLength} right, which is ${(mostRecentAnswer / quizLength * 100).toFixed(0)}%!`
    ]
  
  }

  useEffect(() => {
    (async () => {
      if (loading) return
      if (!user) {
        window.location.href = "/"
        return
      }
    })()
  }, [user])

  return (<>
    <Header />
    <div className='flex flex-col w-full'>
      {userData && userData?.previouslyVotedOnSites && userData?.previouslyVotedOnSites?.map((quiz) => (<>
        <Quiz
          userData={null}
          quizData={{...quiz.postData, id: quiz.id}}
          historyText={(() => {
            try {
              return getHistoryText(quiz) ?? ''
            } catch (e) {
              console.error(e)
              return ['']
            }
          })()}
          key={quiz.id}
        />
      </>))}
    </div>
  </>)
}

export default HistoryPage