import React from "react"
import { FireworksOptions } from "fireworks-js"

export default interface AnimationContextType {
  state: [GlobalAnimation, React.Dispatch<React.SetStateAction<GlobalAnimation>>]
  fireworks: {
    setFireworksOptions: React.Dispatch<React.SetStateAction<FireworksOptions>>
    setFireworksEnabled: (state?: boolean | undefined) => void
  }
}

type Animation = ''

export enum GlobalAnimation {
  None,
  Fireworks,
  Confetti,
  RainbowRain,
}