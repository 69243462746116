import React from 'react'

interface Props {
  text: string
  href: string
}

function ExternalButton(props: Props) {
  const { text, href } = props

  return (<>
    <a
      href={href}
      target='_blank'
      rel='noopener noreferrer'
      className='btn bg-white text-background-100 font-Fira p-2 rounded-2xl'
      onClick={(e) => e.stopPropagation()}
    >
      {text}
    </a>
  </>)
}

export default ExternalButton